<template>
  <div>
    <el-table ref="vmTable" v-loading="loading" :data="tableData">
      <el-table-column prop="fileUrl" label="文件">
        <template slot-scope="scope">
          <span v-for="item in scope.row.files" :key="item.id">
            <img v-if="item.fileType === 1" style="width: 200px;height: 200px;margin-right: 10px;" alt="" :src="item.path"
              @click.self="showBigImage($event)"/>
            <span v-else>
              <video :src="item.path" width="200" height="200" style="margin-right: 10px;" controls></video>
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="suggestion" width="" label="问题描述">
      </el-table-column>
      <el-table-column prop="mobile" label="联系方式" align="center" width="200">
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" align="center" width="200">
      </el-table-column>
    </el-table>
    <big-img :visible="photoVisible" :url="bigImgUrl" @closeClick="photoVisible = false"></big-img>
  </div>
</template>
<script>
import BigImg from "@/components/BigImg";

export default {
  components: { BigImg },
  data() {
    return {
      photoVisible: false,
      bigImgUrl: "",
      loading: false,
      tableData: [],
      search: {
        filter: "",
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$http.get("feedbacks/").then(res => {
        console.log(res);
        this.tableData = res.data.records;
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        if (error.response) {
          this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
        }
      });
    },
    showBigImage(e) {
      console.log(e);
      if (e !== "") {
        this.photoVisible = true;
        this.bigImgUrl = e.target.src;
      }
    },
    handleAdd() {
      this.$refs.editPage.open(0);
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$http.delete("feedbacks/" + row.id).then(res => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
